<template>
  <b-row  ref="parches_table" class="mt-3">
    <b-col sm="12">
      <b-row>
        <b-col sm="2">
          <div class="form-group">
            <label for="">Дата поступлення</label>
            <input type="date" class="form-control-sm form-control" v-model="dateInput" @change="setDateInput">
          </div>
        </b-col>
        <b-col sm="2">
          <div class="form-group">
            <label for="">Курс USD</label>
            <input type="text" class="form-control-sm form-control" v-model="exchangeRate" @change="setRate">
          </div>
        </b-col>
        <b-col sm="8">
          <!-- button save -->
          <div class="form-group mt-4">
            <b-button variant="success" @click="save"><fa icon="save" size="lg"/></b-button>
          </div>
          <!-- /button save -->
        </b-col>
      </b-row>
      <!-- OLD table -->
        <b-table-simple bordered show-empty sort-icon-left small hover responsive>
          <b-thead>
          <ParchesFilter :fields="fields" @get-data="getData"/>
          <b-tr class="table-info">
            <b-th rowspan="2" class="align-top">{{ $t('code') }}</b-th>
            <b-th rowspan="2" class="align-top">{{ $t('group') }}</b-th>
            <b-th rowspan="2" class="align-top">{{ $t('name') }}</b-th>
            <b-th rowspan="2" class="align-top">{{ $t('characteristic') }}</b-th>
            <b-th rowspan="2" class="align-top">{{ $t('production') }}</b-th>
            <b-th rowspan="2" class="align-top text-center">{{ $t('price_usd') }}</b-th>
            <b-th rowspan="2" class="align-top text-center">{{ $t('price') }}</b-th>
            <b-th rowspan="2" class="align-top text-center">{{ $t('price_all') }}</b-th>
            <b-th rowspan="2" class="align-top">{{ $t('reserve') }}</b-th>
            <b-th rowspan="2" class="align-top">{{ $t('free') }}</b-th>
            <b-th v-if="additional" class="align-top">
              {{ $t('expense_for') }}
              <fa icon="cog" class="pointer" @click="showModal('expenseFor')"/>
            </b-th>
            <b-th v-if="additional" class="align-top">
              {{ $t('enough_for') }}
              <fa icon="cog" class="pointer" @click="showModal('enoughFor')"/>
            </b-th>
            <b-th v-if="additional" class="align-top">
              {{ $t('calc_for') }}
              <fa icon="cog" class="pointer" @click="showModal('calcFor')"/>
            </b-th>
            <b-th rowspan="2" class="align-top">{{ $t('order') }}</b-th>
            <b-th v-if="additional && dateInput" colspan="3" class="align-top">
              {{ $t('for_admission_take_into_account_when_calculating') }}
            </b-th>
          </b-tr>
          <b-tr v-if="additional">
            <b-th class="text-center">[{{ expenseFor }}]днів</b-th>
            <b-th class="text-center">[{{ enoughFor }}]днів</b-th>
            <b-th class="text-center">[{{ calcFor }}]днів</b-th>
            <template v-if="dateInput">
              <b-th v-for="(d, j) in dates" class="text-center" :key="j">
                <div class="form-check">
                  <input class="form-check-input" type="checkbox" :id="'checkbox-dates-' + j" :value="d" @change="setCountToItem($event)">
                  <label class="form-check-label" :for="'checkbox-dates-' + j">
                    {{ d }}
                  </label>
                </div>
              </b-th>
            </template>
          </b-tr>
          </b-thead>
          <b-tbody>
          <b-tr v-for="item in items" :key="item.id + 100">
            <b-td>{{ item.sku }}</b-td>
            <b-td>{{ item.group }}</b-td>
            <b-td>{{ item.product }}</b-td>
            <b-td>{{ item.characteristic }}</b-td>
            <b-td class="text-center">
              <span v-if="item.specification">{{ $t('yes') }}</span>
              <span v-else>{{ $t('no') }}</span>
            </b-td>
            <b-td class="text-center" style="width:100px">
<!--              <span class="text-danger fw-bold">{{ item.priceUsd | currencyUsd }}</span>-->
              <input type="text" class="form-control-sm form-control" v-model.number="item.priceUsd" @change="setItemPrice(item.id, item.type)">
            </b-td>
            <b-td class="text-center" style="width:100px">
<!--              <span class="text-success fw-bold">{{ item.price | currency }}</span>-->
              <input type="text" class="form-control-sm form-control" value="0" @change="setProductPrice(item.id, $event)">
            </b-td>
            <b-td class="text-center" style="width:70px">
<!--              <span class="text-info fw-bold">{{ item.priceAll | currency }}</span>-->
              <input type="text" class="form-control-sm form-control" v-model.number="item.priceAll" >
            </b-td>
            <b-td class="text-center">
              {{ item.reserve }}
            </b-td>
            <b-td class="text-center">{{ item.totalQuantity }}</b-td>
            <template v-if="additional">
              <b-td class="text-center">{{ item.expenseFor }}</b-td>
              <b-td class="text-center">{{ item.enoughFor }}</b-td>
              <b-td class="text-center">{{ item.calcFor }}</b-td>
            </template>
            <b-td>
              <input type="text" class="form-control-sm form-control" v-model.number="item.needToOrder" @change="needToOrderPriceCalc(item.id, item.type)">
            </b-td>
            <template v-if="dateInput">
              <b-td v-for="(s, j) in item.orderNeededProductCountAll" :key="j" class="text-center">
                {{ countValues(s) }}
              </b-td>
            </template>
          </b-tr>
          </b-tbody>
        </b-table-simple>
      <b-row>
        <b-col>
          <b-pagination size="sm" :total-rows="totalItems" v-model="currentPage" :per-page="perPage"/>
        </b-col>
        <b-col>
          <b-form-group
            label="К-сть на стр"
            label-for="per-stage-select"
            label-cols-sm="3"
            label-align-sm="right"
            label-size="sm"
            class="mb-0"
          >
            <b-form-select
              id="per-stage-select"
              v-model="perPage"
              :options="pageOptions"
              size="sm"
            ></b-form-select>
          </b-form-group>
        </b-col>
      </b-row>
      <b-modal id="days-modal" size="sm" hide-footer>
        <div class="form-group">
          <label for="name">{{ $t('qty_days') }}</label>
          <input type="number" required class="form-control form-control-sm" v-model="qtyDays" value="0">
        </div>
        <div v-if="dayType === 'calcFor'" class="form-group">
          <label for="name">{{ $t('qty_days') }} 2</label>
          <input type="number" required class="form-control form-control-sm" v-model="qtyDaysSec" value="0">
        </div>
        <div class="form-group">
          <b-btn size="sm" @click="saveQtyDays" variant="primary">{{ $t('save') }}</b-btn>
        </div>
      </b-modal>
    </b-col>
  </b-row>
</template>

<script>
import axios from 'axios'
import ParchesFilter from './filter/parches_filter'
export default {
  components: {
    ParchesFilter
  },
  props: {
    operation: {
      required: true
    },
    currentDate: {
      required: true
    },
    form: {
      required: true
    }
  },
  data: () => ({
    pageOptions: [5, 10, 15, 50, 100, 250, 500, 1000, 1500, 2000, 5000],
    expenseFor: 0,
    enoughFor: 0,
    calcFor: 0,
    dayType: null,
    qtyDays: 0,
    qtyDaysSec: 0,
    dateInput: null,
    exchangeRate: 0,
    items: null,
    totalRows: 1,
    currentPage: 1,
    perPage: 10,
    totalItems: 0,
    urlParams: {},
    dates: null,
    fields: [
      {
        key: 'sku',
        label: 'Код товару',
        typeSort: 'text'
      },
      {
        key: 'group',
        label: 'Група',
        typeSort: 'select'
      },
      {
        key: 'product',
        label: 'Назва',
        typeSort: 'select'
      },
      {
        key: 'characteristic',
        label: 'Характеристика',
        class: 'text-center',
        typeSort: 'select'
      }
    ]
  }),
  computed: {
    additional () {
      const additional = this.operation.additional.filter(s => s.selected)
      return additional.length > 0
    }
  },
  mounted () {
    this.getData()
  },
  methods: {
    async save () {
      const apiUrl = process.env.VUE_APP_ENDPOINT_V1
      const operationData = this.form
      const orderItems = this.items.filter(i => i.needToOrder > 0)
      operationData.dateInput = this.dateInput
      await axios.post(apiUrl + 'finance/purchase', { operationData: operationData, items: orderItems }).then(resp => {
        if (resp.data.status) {
          // message info
          this.getData()
        }
      })
    },
    async getData (params = '') {
      const apiUrl = process.env.VUE_APP_ENDPOINT_V1
      this.urlParams.perPage = this.perPage
      const p = this.buildQuery() !== '' ? '&' + this.buildQuery() + (params !== '' ? '&' + params : '') : ''
      await axios.get(apiUrl + 'finance/products?page=' + this.currentPage + p).then(resp => {
        if (resp.data) {
          const data = resp.data.items
          this.items = data.data
          this.currentPage = data.current_page
          this.perPage = data.per_page
          this.totalItems = data.total
          this.dates = resp.data.dates
        }
      })
    },
    setItemPrice (id, type) {
      const item = this.items.find(i => i.id === id && i.type === type)
      if (typeof item !== 'undefined' && item.priceUsd > 0) {
        item.price = (item.priceUsd * Number.parseFloat(this.exchangeRate)).toFixed(2)
      }
    },
    needToOrderPriceCalc (id, type) {
      const item = this.items.find(i => i.id === id && i.type === type)
      if (typeof item !== 'undefined') {
        item.priceAll = (item.price * item.needToOrder).toFixed(2)
      }
    },
    setRate () {
      this.items.forEach(i => {
        i.priceUsd = this.getConvertedPrice(i.price)
      })
    },
    getConvertedPrice (price) {
      const rate = Number.parseFloat(this.exchangeRate)
      if (this.exchangeRate.length >= 1) {
        const p = Number.parseInt(price)
        const priceInUsd = p / rate //  Ціна USD * Курс USD = Ціна Грн
        return priceInUsd.toFixed(2)
      }
      return 0
    },
    setProductPrice (productId, e) {
      const item = this.items.find(p => p.id === productId)
      if (item) {
        item.price = Number.parseFloat(e.target.value)
        console.log(item)
      }
    },
    showModal (type) {
      this.dayType = type
      this.$bvModal.show('days-modal')
    },
    saveQtyDays (e) {
      this[this.dayType] = Number.parseInt(this.qtyDays)
      this.urlParams[this.dayType] = Number.parseInt(this.qtyDays)
      if (this.dayType === 'calcFor') {
        // this[enoughFor] = Number.parseInt(this.qtyDaysSec)
        this.urlParams.calcForSec = Number.parseInt(this.qtyDaysSec)
      }
      this.$bvModal.hide('days-modal')
      this.getData()
    },
    setDateInput (e) {
      this.urlParams.dateInput = this.dateInput
      this.getData()
    },
    setCountToItem (e) {
      const checked = e.target.checked
      if (checked) {
        const val = e.target.value
        this.items.forEach(i => {
          if (i.orderNeededProductCountAll[val]) {
            i.totalQuantity += this.countValues(i.orderNeededProductCountAll[val])
            if (i.calcFor > 0) {
              const j = i.calcFor - i.totalQuantity
              i.calcFor = j > 0 ? j : 0
            }
          }
        })
      } else {
        this.getData()
      }
    },
    countValues (vals) {
      if (vals.length > 0) {
        return vals.reduce((total, x) => total + x, 0)
      }
      return 0
    },
    buildQuery () {
      return Object.entries(this.urlParams).map(pair => pair.map(encodeURIComponent).join('=')).join('&')
    }
  },
  watch: {
    currentPage: {
      handler: function (value) {
        this.getData()
      }
    },
    perPage: {
      handler: function (value) {
        this.getData()
      }
    }
  }
}
</script>
<style>
.pointer {
  cursor: pointer;
}
.pointer:hover {
  color: #287bb5;
}
</style>

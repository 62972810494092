<template>
  <b-tr v-if="fields">
    <b-td v-for="field in fields" :key="field.key">
      <b-form-input v-if="field.typeSort === 'text'" size="sm" :placeholder="field.label" @keyup.enter="searchByText(field.key, $event)"></b-form-input>
      <div v-if="field.key === 'group'" class="form-group" >
        <select class="form-control form-control-sm" @change="searchByGroup(field.key, $event)">
          <option value=""> -- </option>
          <optgroup v-for="group in groups" :key="group.id" :label="group.name">
            <option v-for="child in group.children" :key="child.id" :value="child.id">{{ child.name }}</option>
          </optgroup>
        </select>
      </div>
      <b-input-group v-if="field.key === 'product'">
        <list-select
          v-if="productOptions"
          :list="productOptions"
          option-value="id"
          option-text="name"
          :placeholder="$t('nothing_selected')"
          :selected-item="productSelected"
          @select="onSelectProduct"
          class="form-control form-control-sm"></list-select>
      </b-input-group>
      <div v-if="field.key === 'characteristic'" class="form-group">
        <list-select
          v-if="characteristicsOptions"
          :list="characteristicsOptions"
          option-value="id"
          option-text="name"
          :placeholder="$t('nothing_selected')"
          :selected-item="characteristicSelected"
          @select="onSelectCharacteristic"
          class="form-control form-control-sm"></list-select>
      </div>
    </b-td>
  </b-tr>
</template>
<script>
import axios from 'axios'
import { ListSelect } from 'vue-search-select'
export default {
  components: {
    ListSelect
  },
  props: {
    fields: {
      required: true
    }
  },
  data: () => ({
    productSelected: {
      id: '',
      name: ''
    },
    characteristicSelected: {
      id: '',
      name: ''
    },
    filterNamesParams: {},
    groups: null,
    characteristicsOptions: null,
    productOptions: null,
    urlParams: {}
  }),
  mounted () {
    this.fetchGroup()
    this.fetchProducts()
  },
  methods: {
    async fetchGroup () {
      const apiUrl = process.env.VUE_APP_ENDPOINT_V1
      await axios.post(apiUrl + 'groups/list').then(resp => {
        this.groups = this.listToTree(resp.data.data)
      })
    },
    async fetchProducts () {
      this.productOptions = null
      this.characteristicsOptions = null
      const apiUrl = process.env.VUE_APP_ENDPOINT_V1
      await axios.post(apiUrl + 'product/names', this.filterNamesParams).then(resp => {
        this.productOptions = resp.data.products
        this.characteristicsOptions = resp.data.characteristics
      })
    },
    onSelectProduct (val) {
      this.urlParams['product'] = val.id
      this.productSelected = val
      this.$emit('get-data', this.buildQuery())
      this.filterNamesParams.product = val.id
      this.fetchProducts()
    },
    onSelectCharacteristic (val) {
      this.urlParams['characteristic'] = val.name === ' -- ' ? '' : val.name
      this.characteristicSelected = val
      this.$emit('get-data', this.buildQuery())
      if (val.name !== ' -- ') {
        this.filterNamesParams.characteristic = val.name
      }
      this.fetchProducts()
    },
    clearFilter () {
      this.urlParams = {}
      this.$emit('get-data', this.buildQuery())
    },
    searchByText (key, e) {
      this.urlParams[key] = e.target.value
      this.$emit('get-data', this.buildQuery())
    },
    searchBySelect (key, e) {
      const value = e.target.value
      this.urlParams[key] = value
      this.$emit('get-data', this.buildQuery())
    },
    searchByGroup (key, e) {
      const value = e.target.value
      // clear params
      this.urlParams = {}
      // set params
      this.urlParams[key] = value
      // clear selected
      this.productSelected = {}
      this.characteristicSelected = {}
      // clear params
      this.filterNamesParams = {}
      // set param
      this.filterNamesParams.group = value
      // refresh request
      this.fetchProducts()
      this.$emit('get-data', this.buildQuery())
    },
    buildQuery () {
      return Object.entries(this.urlParams).map(pair => pair.map(encodeURIComponent).join('=')).join('&')
    },
    listToTree (list) {
      const map = {}
      let node
      const roots = []
      for (let i = 0; i < list.length; i += 1) {
        map[list[i].id] = i
        list[i].children = []
      }

      for (let i = 0; i < list.length; i += 1) {
        node = list[i]
        if (node.parent_id > 0) {
          // if you have dangling branches check that map[node.parentId] exists
          list[map[node.parent_id]].children.push(node)
        } else {
          roots.push(node)
        }
      }
      return roots
    }
  }
}
</script>

<template>
  <b-row class="mt-3">
    <b-col sm="12">
      <b-row>
        <b-col sm="2">
          <div class="form-group">
            <label for="">Дата поступлення</label>
            <input type="text" class="form-control-sm form-control">
          </div>
        </b-col>
        <b-col sm="2">
          <div class="form-group">
            <label for="">Курс USD</label>
            <input type="text" class="form-control-sm form-control">
          </div>
        </b-col>
      </b-row>
      <div class="table-responsive">
        <table class="table table-sm">
          <thead>
          <tr class="table-info">
            <th rowspan="2" class="align-top">{{ $t('code') }}</th>
            <th rowspan="2" class="align-top">{{ $t('group') }}</th>
            <th rowspan="2" class="align-top">{{ $t('name') }}</th>
            <th rowspan="2" class="align-top">{{ $t('characteristic') }}</th>
            <th rowspan="2" class="align-top">{{ $t('production') }}</th>
            <th rowspan="2" class="align-top">{{ $t('price_usd') }}</th>
            <th rowspan="2" class="align-top">{{ $t('price') }}</th>
            <th rowspan="2" class="align-top">{{ $t('reserve') }}</th>
            <th rowspan="2" class="align-top">{{ $t('free') }}</th>
            <th class="align-top">
              {{ $t('expense_for') }}
              <fa icon="cog" class="pointer"/>
            </th>
            <th class="align-top">
              {{ $t('enough_for') }}
              <fa icon="cog" class="pointer"/>
            </th>
            <th class="align-top">
              {{ $t('calc_for') }}
              <fa icon="cog" class="pointer"/>
            </th>
            <th rowspan="2" class="align-top">{{ $t('order') }}</th>
            <th colspan="3" class="align-top">{{ $t('for_admission_take_into_account_when_calculating') }}</th>
          </tr>
          <tr>
            <th class="text-center">[{{ expenseFor}}]днів</th>
            <th class="text-center">[{{ enoughFor }}]днів</th>
            <th class="text-center">[{{ calcFor }}]днів</th>
            <th class="text-center">{{ currentDate }}</th>
            <th class="text-center">{{ currentDate }}</th>
            <th class="text-center">{{ currentDate }}</th>
          </tr>
          </thead>
        </table>
      </div>
    </b-col>
  </b-row>
</template>

<script>
export default {
  props: {
    operation: {
      required: true
    },
    currentDate: {
      required: true
    }
  },
  data: () => ({
    expenseFor: 30,
    enoughFor: 0,
    calcFor: 100
  })
}
</script>
<style>
.pointer {
  cursor: pointer;
}
.pointer:hover {
  color: #287bb5;
}
</style>

<template>
  <b-row>
    <b-col sm="7">
      <b-button variant="success" @click="save">{{ btnSaveName }}</b-button>
    </b-col>
    <b-col sm="5" class="text-right">
      <h6>
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text">Відстрочка не оплачених на </span>
          </div>
          <input type="text" class="form-control" v-model.number="postponement">
          <div class="input-group-append">
            <span class="input-group-text">днів</span>
          </div>
        </div>
      </h6>
      <h6>Отримано: {{ receivedAmount }}</h6>
      <h6>Винен нам: {{ oweUsAmount }}</h6>
      <h6>Сальдо: {{ balanceAmount }}</h6>
    </b-col>
    <b-col sm="12">
      <hr>
      <b-table-simple bordered show-empty sort-icon-left small hover responsive>
        <b-thead>
          <CalcDropFilter :fields="fields" @get-data="getData" />
          <b-tr>
            <b-th>Н/з</b-th>
            <b-th>Дата</b-th>
            <b-th>Статус Зам.</b-th>
            <b-th>Статус Дост.</b-th>
            <b-th>Доставка</b-th>
            <b-th>Оплата</b-th>
            <b-th>Сума Зам.</b-th>
            <b-th>Завдаток</b-th>
            <b-th>Статус Опл.</b-th>
            <b-th>Оплачено</b-th>
            <b-th>До Оплати</b-th>
            <b-th>Статус Опл.</b-th>
            <b-th>Оплачено</b-th>
            <b-th>Сума Дроп.</b-th>
            <b-th>Оплачено</b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr v-for="item in items" :key="item.id">
            <b-td>{{ item.orderNum }}</b-td>
            <b-td>{{ item.created }}</b-td>
            <b-td class="text-center">{{ item.orderStatus }}</b-td>
            <b-td class="text-center">{{ item.deliveryStatus }}</b-td>
            <b-td class="text-center">{{ item.delivery }}</b-td>
            <b-td class="text-center">{{ item.payment }}</b-td>
            <b-td class="text-center">{{ item.total | currency }}</b-td>
            <b-td class="text-center">{{ item.deposit | currency }}</b-td>
            <b-td class="text-center">
              <span v-if="item.depositPaymentStatus === 'paid'">{{ $t('paid') }}</span>
              <span v-else-if="item.depositPaymentStatus === 'unpaid'">{{ $t('unpaid') }}</span>
            </b-td>
            <b-td class="text-center">
              <span v-if="item.depositCheckbox">
                <span v-if="item.depositCheckbox && item.depositCheckbox.checkbox">
                  <input type="checkbox" v-model="item.depositCheckbox.value">
                </span>
                <span v-else>
                  <span :class="item.depositCheckbox.class">{{ item.depositCheckbox.value }}</span>
                </span>
              </span>
            </b-td>
            <b-td class="text-center">
              {{ item.toPay | currency }}
            </b-td>
            <b-td class="text-center">
              {{ item.paymentStatus }}
            </b-td>
            <b-td class="text-center">
              <span v-if="item.checkboxPaid">
                <span v-if="item.checkboxPaid && item.checkboxPaid.checkbox">
                  <input type="checkbox" v-model="item.checkboxPaid.value">
                </span>
                <span v-else>
                  <span :class="item.checkboxPaid.class">{{ item.checkboxPaid.value }}</span>
                </span>
              </span>
              <span v-else>
                <span class="text-warning">-</span>
              </span>
            </b-td>
            <b-td class="text-center">{{ item.dropAmount | currency }}</b-td>
            <b-td class="text-center">
              <span v-if="item.dropPaid">
                <span v-if="item.dropPaid && item.dropPaid.checkbox">
                  <input type="checkbox" v-model="item.dropPaid.value">
                </span>
                <span v-else>
                  <span :class="item.dropPaid.class">{{ item.dropPaid.value }}</span>
                </span>
              </span>
            </b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
      <b-row>
        <b-col>
          <b-pagination size="sm" :total-rows="totalItems" v-model="currentPage" :per-page="perPage"/>
        </b-col>
        <b-col>
          <b-form-group
            label="К-сть на стр"
            label-for="per-stage-select"
            label-cols-sm="3"
            label-align-sm="right"
            label-size="sm"
            class="mb-0"
          >
            <b-form-select
              id="per-stage-select"
              v-model="perPage"
              :options="pageOptions"
              size="sm"
            ></b-form-select>
          </b-form-group>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import axios from 'axios'
import CalcDropFilter from './filter/calcdrop_filter'
export default {
  components: {
    CalcDropFilter
  },
  props: {
    operation: {
      required: true
    },
    currentDate: {
      required: true
    },
    form: {
      required: true
    },
    userId: {
      default: null
    }
  },
  data: () => ({
    pageOptions: [5, 10, 15, 50, 100, 250, 500, 1000, 1500, 2000, 5000],
    btnSaveName: 'Зберегти',
    postponement: 20,
    currentPage: 1,
    perPage: 15,
    totalItems: 0,
    urlParams: {},
    items: null,
    fields: [
      {
        key: 'num_order',
        label: 'Н/з',
        typeSort: 'text'
      },
      {
        key: 'date',
        label: 'Дата',
        typeSort: 'text'
      },
      {
        key: 'order_status',
        label: 'Статус Зам.',
        typeSort: 'select'
      },
      {
        key: 'delivery_status',
        label: 'Статус Дост.',
        class: 'text-center',
        typeSort: 'select'
      },
      {
        key: 'delivery',
        label: 'Доставка',
        typeSort: 'select'
      },
      {
        key: 'payment',
        label: 'Оплата',
        typeSort: 'select'
      },
      {
        key: 'total',
        label: 'Сума Зам.',
        typeSort: 'text'
      },
      {
        key: 'deposit',
        label: 'Завдаток',
        typeSort: 'text'
      },
      {
        key: 'depositStatus',
        label: 'Статус Опл.',
        typeSort: 'select'
      },
      {
        key: 'paymentStatus',
        label: 'Оплачено',
        typeSort: 'select'
      },
      {
        key: 'toPay',
        label: 'До Оплати',
        typeSort: 'text'
      },
      {
        key: 'depositPaymentStatus',
        label: 'Статус Опл.',
        typeSort: 'select'
      },
      {
        key: 'checkboxPaid',
        label: 'Оплачено',
        typeSort: 'text'
      },
      {
        key: 'dropAmount',
        label: 'Сума Дроп.',
        typeSort: 'text'
      },
      {
        key: 'dropPaid',
        label: 'Оплачено',
        typeSort: 'text'
      }
    ]
  }),
  mounted () {
    this.getData()
    // this.$parent.form.sum = 200
  },
  computed: {
    receivedAmount () {
      if (this.items) {
        let depositTotal = 0
        let toPay = 0
        this.items.forEach(item => {
          if (item.depositCheckbox && item.depositCheckbox.checkbox && item.depositCheckbox.value) {
            depositTotal += Number.parseFloat(item.deposit)
            toPay += Number.parseFloat(item.toPay)
          }
        })
        const calc = depositTotal + toPay
        this.$parent.form.received = calc
        return calc
      }
      this.$parent.form.received = 0
      return 0
    },
    oweUsAmount () {
      if (this.items) {
        let total = 0
        this.items.forEach(item => {
          if (item.depositCheckbox.checkbox && item.depositCheckbox.value && item.dropAmount) {
            total += Number.parseFloat(item.deposit)
          }
        })
        this.$parent.form.oweUs = total
        return total
      }
      this.$parent.form.oweUs = 0
      return 0
    },
    balanceAmount () {
      const calc = this.receivedAmount - this.oweUsAmount
      const balance = calc < 0 ? 0 : calc
      if (balance > 0) { // Якшо Сальдо значення Плюсове. Тип Фін. Оп. ставим значення Витрата, Якшо Сальдо значення Мінусове. Тип Фін. Оп. ставим значення Прихід
        this.$parent.form.financeType = 'output'
      } else {
        this.$parent.form.financeType = 'input'
      }
      this.$parent.form.sum = balance
      this.$parent.form.sumPaid = balance
      this.$parent.form.balance = balance
      return balance
    }
  },
  methods: {
    getData (params = '') {
      this.urlParams.perPage = this.perPage
      this.urlParams.postponement = this.postponement
      const p = this.buildQuery() !== '' ? '&' + this.buildQuery() + (params !== '' ? '&' + params : '') : ''
      const url = process.env.VUE_APP_ENDPOINT_V1 + 'finance/calcdrop?page=' + this.currentPage + p
      axios.get(url).then(resp => {
        if (resp.data) {
          const data = resp.data
          this.items = data.data
          this.currentPage = data.current_page
          this.perPage = data.per_page
          this.totalItems = data.total
        }
      })
    },
    async save () {
      const operationData = this.form
      const url = process.env.VUE_APP_ENDPOINT_V1 + 'finance/calcdrop'
      await axios.post(url, { operationData: operationData, items: this.items }).then(resp => {
        if (resp.data) {
          this.btnSaveName = 'Збережено: ' + resp.data.lastCreatedDatetime
          this.$parent.form.reset()
          this.getData()
        }
      })
    },
    buildQuery () {
      return Object.entries(this.urlParams).map(pair => pair.map(encodeURIComponent).join('=')).join('&')
    }
  },
  watch: {
    currentPage: {
      handler: function (value) {
        this.getData()
      }
    },
    perPage: {
      handler: function (value) {
        this.getData()
      }
    },
    userId: {
      handler: function (val, oldVal) {
        this.urlParams.userId = this.userId
        this.getData()
      }
    }
  }
}
</script>

<template>
  <b-tr v-if="fields">
    <b-td v-for="field in fields" :key="field.key">
      <b-form-input v-if="field.typeSort === 'text'" size="sm" :placeholder="field.label" @keyup.enter="searchByText(field.key, $event)"></b-form-input>
      <div v-if="field.key === 'delivery'" class="form-group" @change="setDelivery">
        <select v-if="deliveries && deliveries" class="form-control form-control-sm">
          <option value=""> -- </option>
          <option v-for="delivery in deliveries" :key="delivery.id" :value="delivery.id">{{ delivery.name }}</option>
        </select>
      </div>
      <div v-else-if="field.key === 'payment'" class="form-group">
        <select v-if="payments && payments" class="form-control form-control-sm" @change="setPaymentsFilter">
          <option value=""> -- </option>
          <option v-for="payment in payments" :key="payment.id" :value="payment.id">{{ payment.name }}</option>
        </select>
      </div>
<!--      <div v-else-if="field.key === 'paymentStatus'" class="form-group">-->
<!--        <select v-if="payments && payments" class="form-control form-control-sm" @change="setPaymentsFilter">-->
<!--          <option value=""> &#45;&#45; </option>-->
<!--          <option v-for="payment in paymentStatuses" :key="payment.id" :value="payment.id">{{ payment.name }}</option>-->
<!--        </select>-->
<!--      </div>-->
      <div v-else-if="field.key === 'depositStatus'" class="form-group">
        <select class="form-control form-control-sm" @change="setDepositStatus">
          <option value=""> -- </option>
          <option value="paid">{{ $t('paid') }}</option>
          <option value="unpaid">{{ $t('unpaid') }}</option>
        </select>
      </div>
      <div v-else-if="field.key === 'depositPaymentStatus'" class="form-group">
        <select class="form-control form-control-sm" @change="setDepositStatus">
          <option value=""> -- </option>
          <option value="paid">{{ $t('paid') }}</option>
          <option value="unpaid">{{ $t('unpaid') }}</option>
        </select>
      </div>
      <div v-else-if="field.key === 'order_status'" class="form-group">
        <select class="form-control form-control-sm" @change="setOrderStatus">
          <option value=""> -- </option>
          <option v-for="status in orderStatuses" :key="status.id" :value="status.id">{{ status.name }}</option>
        </select>
      </div>
      <div v-else-if="field.key === 'delivery_status'" class="form-group">
        <select class="form-control form-control-sm" @change="setDeliveryStatus">
          <option value=""> -- </option>
          <option v-for="status in deliveryStatuses" :key="status.id" :value="status.id">{{ status.name }}</option>
        </select>
      </div>
    </b-td>
  </b-tr>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'

export default {
  props: {
    fields: {
      required: true
    }
  },
  data: () => ({
    urlParams: {}
  }),
  mounted () {
    this.setStatuses()
    this.setDeliveries()
    this.setDeliveries()
    this.setPayments()
  },
  computed: {
    ...mapGetters({
      deliveries: 'order/deliveries',
      payments: 'order/payments',
      statuses: 'order/statuses'
    }),
    // eslint-disable-next-line vue/return-in-computed-property
    orderStatuses () {
      if (this.statuses) {
        return this.statuses.filter(s => s.type === 'order')
      }
    },
    // eslint-disable-next-line vue/return-in-computed-property
    paymentStatuses () {
      if (this.statuses) {
        return this.statuses.filter(s => s.type === 'payment')
      }
    },
    // eslint-disable-next-line vue/return-in-computed-property
    deliveryStatuses () {
      if (this.statuses) {
        return this.statuses.filter(s => s.type === 'delivery')
      }
    }
  },
  methods: {
    ...mapActions({
      setDeliveries: 'order/setDeliveries',
      setPayments: 'order/setPayments',
      setStatuses: 'order/setStatuses'
    }),
    searchByText (key, e) {
      this.urlParams[key] = e.target.value
      this.$emit('get-data', this.buildQuery())
    },
    setDelivery (e) {
      this.urlParams['delivery'] = e.target.value
      this.$emit('get-data', this.buildQuery())
    },
    setPaymentsFilter (e) {
      this.urlParams['payment'] = e.target.value
      this.$emit('get-data', this.buildQuery())
    },
    setDeliveryStatus (e) {
      this.urlParams['deliveryStatus'] = e.target.value
      this.$emit('get-data', this.buildQuery())
    },
    setOrderStatus (e) {
      this.urlParams['orderStatus'] = e.target.value
      this.$emit('get-data', this.buildQuery())
    },
    setDepositStatus (e) {
      this.urlParams['depositPaymentStatus'] = e.target.value
      this.$emit('get-data', this.buildQuery())
    },
    buildQuery () {
      return Object.entries(this.urlParams).map(pair => pair.map(encodeURIComponent).join('=')).join('&')
    }
  }
}
</script>
